// BS4 variables to override:
//  https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// Figma source
// https://www.figma.com/file/51aodDiMpZgbw4k70y2LH9/%5B2021%5D-Shift-Ant-Component-Guide?node-id=715%3A261

@import 'resources/assets/bootstrap/scss/functions';

/*
 * Gray Numbered Colors
 */

$white: #ffffff !default;
$gray-100: #fafafa !default;
$gray-150: #f8f8f8 !default;
$gray-200: #f5f5f5 !default;
$gray-300: #f1f1f1 !default;
$gray-320: #f2f2f2 !default;
$gray-400: #e8e8e8 !default;
$gray-430: #eaeaea !default;
$gray-450: #e5e5e5 !default;
$gray-500: #d9d9d9 !default;
$gray-600: #bfbfbf !default;
$gray-700: #8c8c8c !default;
$gray-750: #9a9a9a !default;
$gray-800: #595959 !default;
$gray-900: #262626 !default;
$blue-gray: #d2e1ee !default;
$blue-gray-darker: #6c757d !default;
$gray-medium-dark: #7d7d7d !default;
$gray-light-gray: #e0e0e3 !default;
$black: #000000 !default;
$dark-gray: #393939 !default;

/*
 * Blue Numbered Colors
 */

$blue-100: #f7faff !default;
$blue-200: #f0f5ff !default;
$blue-300: #e0e9ff !default;
$blue-350: #d6dbf6 !default;
$blue-450: #cdd9e1 !default;
$blue-400: #b8c9ff !default;
$blue-500: #6682ff !default;
$blue-600: #3d59fa !default;
$blue-650: #2c3540 !default;
$blue-700: #1839f9 !default;
$blue-800: #134187 !default;
$blue-900: #080961 !default;

/*
* Blue Royal Colors
*/
$blue-royal-600: #003fdc !default;

/*
 * Green Numbered Colors
 */

$green-100: #f6ffed !default;
$green-200: #e2f6d6 !default;
$green-300: #b7eb8f !default;
$green-500: #73d13d !default;
$green-600: #2ab756 !default;
$green-700: #389e0d !default;
$green-800: #07831d !default;
$green-900: #015e0a !default;
$apple-green: #deede5 !default;

/*
 * Red Numbered Colors
 */

$red-100: #fff1f0 !default;
$red-300: #ffa39e !default;
$red-400: #ff7875 !default;
$red-500: #ff4d4f !default;
$red-600: #cf1322 !default;

/*
 * Amber Numbered Colors
 */

$amber-100: #fffbe6 !default;
$amber-300: #ffe58f !default;
$amber-500: #ffc53d !default;
$amber-600: #faad14 !default;
$amber-700: #d48806 !default;
$amber-800: #d97909 !default;
$amber-900: #ac5606 !default;

/*
 * Indigo Numbered Colors
 */

$indigo-100: #f6e9fa !default;
$indigo-300: #e5bcef !default;
$indigo-500: #bc63e8 !default;
$indigo-600: #a920cb !default;
$indigo-700: #8314b7 !default;

/*
 * Purple Numbered Colors
 */

$purple-100: #f9f0ff !default;
$purple-200: #ecdeff !default;
$purple-300: #d5c0f1 !default;
$purple-500: #722ed1 !default;
$purple-600: #722ed1 !default;
$purple-700: #530285 !default;
$muted-purple: #83528b !default;

/*
 * Pink Numbered Colors
 */

$pink-100: #fff0f6 !default;
$pink-300: #ffadd2 !default;
$pink-600: #df217e !default;

/*
 * Orange Numbered Colors
 */

$orange-100: #fff7e6 !default;
$orange-300: #ffd591 !default;
$orange-400: #ffbb29 !default;
$orange-500: #ffb727 !default;
$orange-600: #fa8c16 !default;

/*
 * Yellow Numbered Colors
 */

$yellow-100: #feffe6 !default;
$yellow-300: #fffb8f !default;
$yellow-600: #ffd84b !default;

/*
 * Teal Numbered Colors
 */

$teal-100: #e6fffb !default;
$teal-300: #87e8de !default;
$teal-600: #13c2c2 !default;

/*
 * Cyan Numbered Colors
 */

$cyan-100: #e4faff !default;
$cyan-300: #bce7f4 !default;
$cyan-600: #38b8d2 !default;
$cyan-700: #0c85ad !default;
$cyan-800: #03608e !default;

/*
 * Data Numbered Colors
 */

$data-red: #cf1322 !default;
$data-amber: #faad14 !default;
$data-teal: #13c2c2 !default;
$data-blue-500: #6682ff !default;

/*
 * Background Colors
 */

$background-gray-100: #fafafa !default;
$background-blue-100: #f7faff !default;
$background-blue-900: #080961 !default;
$background-gray-900: #262626 !default;

/*
 * Other Named Colors
 */

$indigo: #a920cb !default;
$indigo-dark: #7f009e !default;
$indigo-light: #daabeb !default;
$indigo-lighter: #efe6f9 !default;
$indigo-pink: #f6e9fa !default;
$indigo-pink-darker: #e7c1f1 !default;
$purple: #722ed1 !default;
$purple-lighter: #f2e4f6 !default;
$pink: #dd2995 !default;
$pink-light: #fefaff !default;
$pink-dark: #dd2995 !default;
$hot-pink: #ff2891 !default;
$red: #cf1322 !default;
$red-bright: #fa4344 !default;
$red-brightest: #ff0000 !default;
$red-lighter: #fff1f0 !default;
$red-medium-light: #fbe7e7 !default;
$red-dark: #f43727 !default;
$red-light: #f6d6d6 !default;
$orange: #fa8c16 !default;
$amber: #faad14 !default;
$yellow: #ffd84b !default;
$yellow-default: #f3b95c !default;
$yellow-dark: #ffc35c !default;
$green: #2ab756 !default;
$green-light: #8de1a9 !default;
$green-bright: #00a079 !default;
$teal: #13c2c2 !default;
$teal-bright: #20cbad !default;
$cyan: #38b8d2 !default;
$cyan-bright: #75decc !default;
$blue-bright: #1825ad !default;
$cyan-500: #91d8e7 !default;
$cyan-300: #d7f1f6 !default;
$cyan-200: #ebf8fb !default;
$gold-100: #fffbe6 !default;
$gold-300: #ffe58f !default;

/*
 * Usage-specific Colors:
 */
$sticky-note-header: #faad14 !default;
$sticky-note-body: #faad14 !default;

/*
 * Deprecated Colors
 */

$pastel-blue: #f2fbff;
$pastel-blue-2: $blue-100;
$pastel-blue-3: #e0e9ff;
$pastel-green: #d4f5ef;
$pastel-indigo: #f9e1ff;
$pastel-purple: #ece2ff;
$pastel-orange: #ffe3ce;
$pastel-gray: #595959;

/*
 * App Layout
 */
$blue-nav-full-width: 200px;

/*
 * Further Mapping (no new colors defined past this point)
 */

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);
$blues: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$blues: map-merge(
  (
    '100': $blue-100,
    '200': $blue-200,
    '300': $blue-300,
    '400': $blue-400,
    '500': $blue-500,
    '600': $blue-600,
    '700': $blue-700,
    '800': $blue-800,
    '900': $blue-900,
  ),
  $blues
);

/*
* Gray Named Color Mapping
*/

$gray: $gray-800 !default;

/*
* Blue Named Color Mapping
*/
$aliceblue: $blue-100 !default;
$blue-violet: $blue-200 !default;
$blue-light: $blue-300 !default;
$blue-lighter: $blue-400 !default;
$blue: $blue-500 !default;
$brand-hovered: $blue-500 !default; // semantic name for brand/primary hover (this is the color ant uses)
$brand: $blue-600 !default;
$royalBlue: $blue-600 !default;
$havelock-blue: $blue-800 !default;
$navy: $blue-900 !default;
$navy-dark: $blue-900 !default;
$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray,
    'gray-dark': $gray-800,
  ),
  $colors
);

/*
 * Bootstrap Color Mapping
*/

$primary: $brand !default;
$secondary: $gray-800 !default;
$success: $teal !default;
$info: $cyan !default;
$warning: $orange !default;
$danger: $red !default;
$danger-light: $red-lighter !default;
$light: lighten($gray-200, 3%);
$dark: $gray-800 !default;
$darkerGray: #2f2f2f;

/*
 * Red Numbered Colors
 */

$red-100: #fff1f0 !default;
$red-300: #ffa39e !default;
$red-400: #ff7875 !default;
$red-500: #ff4d4f !default;
$red-600: #cf1322 !default;

/*
 * Amber Numbered Colors
 */

$amber-100: #fffbe6 !default;
$amber-300: #ffe58f !default;
$amber-500: #ffc53d !default;
$amber-600: #faad14 !default;
$amber-700: #d48806 !default;

/*
 * Yellow Numbered Colors
 */

$yellow-100: #feffe6 !default;
$yellow-300: #fffb8f !default;
$yellow-600: #ffd84b !default;

/*
  * Teal Numbered Colors
  */

$teal-100: #e6fffb !default;
$teal-300: #87e8de !default;
$teal-600: #13c2c2 !default;
$pink-500: #eb2f96 !default;
$navy-active: lighten($navy, 5%);
$navy-fg3: fade_out($white, 0.6);
$navy-fg4: fade_out($gray-200, 0.7);
$primary-dark: darken($primary, 15%);
$primary-darker: darken($primary, 30%);
$primary-light: lighten($primary, 15%);
$primary-lighter: lighten($primary, 30%);
$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'primary-dark': $blue-900,
  ),
  $theme-colors
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
) !default;

// Body
$body-font: 16px;
$body-bg: $white;
$body-color: $gray-800 !default;

// Links
//
// Style anchor elements.
$link-color: theme-color('primary') !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: none !default;

// Borders
$border-color: $gray-400 !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-family-open-sans-sans-serif: Open Sans, Helvetica, sans-serif;
$font-family-default: $font-family-open-sans-sans-serif !default;
$text-color: $body-color;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-light-semi-bold: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: $font-weight-light-semi-bold !default; // Default value use by bootstrap reboot

$font-weight-base: $font-weight-light !default;
$line-height-base: 1.625 !default;
$h1-font-size: $font-size-base * 1.875 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base * 1.125 !default;
$h1-color: $gray-800 !default;
$h2-color: $black !default;
$h3-color: $black !default;
$h4-color: $gray-800 !default;
$h5-color: $blue-900 !default;
$h6-color: $gray-900 !default;
$h1-font-weight: $font-weight-light !default;
$h2-font-weight: $font-weight-light-semi-bold !default;
$h3-font-weight: $font-weight-light-semi-bold !default;
$h4-font-weight: $font-weight-light-semi-bold !default;
$h5-font-weight: $font-weight-light !default;
$h6-font-weight: $font-weight-light-semi-bold !default;
$h1-line-height: 2.5rem !default;
$h2-line-height: 2.125rem !default;
$h3-line-height: 2.125rem !default;
$h4-line-height: 1.875rem !default;
$h5-line-height: 1.875rem !default;
$h6-line-height: 1.75rem !default;
$headings-margin-bottom: 1rem !default;
$headings-font-family: inherit !default;
$headings-font-weight: $font-weight-light-semi-bold !default;
$headings-line-height: 1.75rem !default;
$headings-color: $black !default;
$text-muted: $gray !default;
$box-shadow-sm: 0 0.095rem 0.25rem rgba($black, 0.035) !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.3rem !default;
$table-accent-bg: #f8f9fa;
$content-component-bg: $blue-100;

// Navbar
$navbar-default-bg: $white;
$navbar-light-svg: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$primary}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
$navbar-light-toggler-icon-bg: str-replace($navbar-light-svg, '#', '%23') !default;

// Tabs
$content-tab-padding: 0.9rem 2rem !important;
$content-tab-font-weight: $font-weight-light-semi-bold;
$content-tab-border-radius: 0.5rem;
$content-tab-line-height: 1.25rem;
$content-tab-color-active-text: $white;
$content-tab-color-inactive-text: $gray-800;
$content-tab-color-inactive-bg: $gray-300;
$content-tab-color-border: $gray-500;
$content-tab-color-default: $blue-500;
$content-tab-color-improve: #faad14;
$content-tab-color-discuss: #faad14;
$content-tab-color-celebrate: #13c2c2;

// Buttons
$btn-border-radius: 3px;
$btn-border-radius-sm: 3px;
$btn-border-radius-lg: 3px;

// Forms

// Inputs
$label-margin-bottom: 0.2rem !default;
$input-border: $border-color;
$input-border-focus: lighten($primary, 25%);
$input-color: $gray-800;
$input-color-placeholder: $gray-600;

// Panels
$panel-default-heading-bg: $white;

// Modals
//
// Padding applied to the modal body
$modal-inner-padding: 0.8rem 1rem !default;

// Alerts
//
// Define alert colors, border radius, and padding.
$alert-padding-y: 0.3rem !default;
$alert-padding-x: 1rem !default;

// Background / Gradient
$bg-blue-gradient: linear-gradient(
  131deg,
  rgb(39 238 250) 0%,
  rgb(78 177 247) 59%,
  rgb(104 136 244) 100%
);

// This is the shiny new gradient,
// the one above is still used else-where
// so can't remove.
$bg-blue-gradient-new: linear-gradient(180deg, $blue-500 0%, $blue-600 100%);
$bg-yellow-white-gradient: linear-gradient(180deg, #ffe7ba 0%, $white 30%);

// misc
//
$generic-drop-shadow: drop-shadow(0 1px 1px rgb(0 0 0 / 15%)) drop-shadow(0 1px 3px rgb(0 0 0 / 8%));
